<template>
  <div
    v-if="isLoading"
    class="inline-block animate-pulse rounded text-neutral-6"
  >
    <IconPzo name="image" class="h-full w-full" />
  </div>
  <div
    v-else-if="error"
    class="inline-block rounded text-neutral-6 contrast:text-base-1"
  >
    <IconPzo name="image" class="h-full w-full" />
  </div>
  <img v-else :src="src" :alt="alt" />
</template>
<script setup lang="ts">
import { useImage } from "@vueuse/core";
import IconPzo from "./IconPzo.vue";

interface Props {
  src: string;
  alt: string;
}

const props = defineProps<Props>();

const { isLoading, error } = useImage({ src: props.src });
</script>
